//@import '../../styles/main.scss';

.medium-container{
    margin: auto;
    width:620px;
}
.tel-number {
    white-space: pre;
}
@media only screen and (max-width: 767px){
    .medium-container{
      width:100%;
    }
}

@media only screen and (max-width: 767px) and (orientation:landscape) {
    .medium-container{
        width:100%;
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 769px) {
    .medium-container{
        width:100%;
    }
  }