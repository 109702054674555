

  .displayNone {
    display: none;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background:rgba(0, 0, 0, 0.7);
    z-index: 9999;
    overflow:auto;
  }
  
  .modal-main {
    border-radius: 8px;
    position: relative;
    margin: 1.78571429em auto .71428571em;
    background-color: #ffffff !important;
    top:20%;
    max-width: 400px;
    padding: 2rem 0px 2rem 0px;
    .modal-buttons-group{
      margin-top:36px;
      display:flex;
    }
  }
.spacing{
  padding-top:5px;
  padding-right:36px;
  padding-left: 36px;
}
    
  

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }
 
  .fieldset-bottom {
    padding-bottom: 0px;
  }

.modal-policy {
    display:flex;
    flex-direction: column;
    background: white;
    width:900px;
    //position: absolute;
    //left,right,margin are centering the modal
    margin: 10% auto;
    border-radius: 8px;
    text-align: center;
    .xButton{
      float:right;
      width: 48px;
      height: 48px;
      padding: 10px;
      cursor: pointer;
    }
    .modalTitle{
      text-align: center;
      padding-top: 12px;
    }
    .columns{
      column-count: 2;
      column-rule:1px solid lightgray;
      padding-top:40px;
      padding-bottom:40px;
      .imgGroup{
        display:inline-block;
        margin-bottom: 25px;
        width:100%;
        
      }
    }
    
    .borderRight{
      border-right:1px solid lightgray;
      @media only screen and (max-width: 768px){
        border-right:0;
      }
    }
  }
  .modalImage{
    width: 380px;
    height: 220px;
    margin: 10px;
  }
  
  .closeButton{
    column-span: all;
  }

  .heading {
    padding: 20px;
  }

  .noscroll{
    overflow:hidden;
  }

  
@media only screen and (max-width: 480px) {
  .modal-main {
    max-width:90%;
  }
  .modal-policy .columns{
    column-count: 1;
    column-rule: none;
  }
}
 /* Tablet - view */
 @media only screen and (min-width: 767px) and (max-width: 769px) {
  // .modal-main {
  //   max-width: 600px;
  // }
 }
 @media only screen and (min-width: 1023px) and (max-width: 1025px) {
  // .modal-main {
  //   max-width: 700px;
  // }
 }

 

